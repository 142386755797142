import Vue from 'vue'
import VueRouter from 'vue-router'
import User from "@/Helpers/User";
import routes from "@/router/routes";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && !User.isLoggedIn()) {
        next({name: 'login'})
    }else if (to.name === 'login' && User.isLoggedIn()) {
        next({name: 'home'})
    }else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (User.isAdmin()) {
            next()
        } else {
            next({
                path: '/home',
                query: { redirect: to.fullPath }
            })
        }
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (User.isLoggedIn()) {
            next()
        } else {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            })
        }
    } else {
        next()
    }
})


export default router
