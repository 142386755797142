import axios from '@/axios'
import AppStorage from "@/Helpers/AppStorage";
import Token from "@/Helpers/Token";
import User from "@/Helpers/User";

const state = {
    users: [],
    user: '',
};

const getters = {
    users: (state) => state.users,
    user: () => User.getUser(),
};

const actions = {
    createUser({commit}, user) {
        return new Promise((resolve, reject) => {
            axios.post('/users', user)
                .then(response => {
                    commit('CREATE_USER', response.data.user)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchUsers({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/users')
                .then(res => {
                    commit('FETCH_USERS', res.data)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    fetchUser({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.get('users/' + id)
                .then(res => {
                    commit('FETCH_USER', res.data.user)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    editUser({commit}, user) {
        return new Promise((resolve, reject) => {
            axios.put('admin/users/' + user.id, user)
                .then(res => {
                    commit('EDIT_USER', res.data.user)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    updatePassword({commit}, user) {
        return new Promise((resolve, reject) => {
            axios.post('admin/users/' + user.id + '/updatePassword', user)
                .then(res => {
                    commit('EDIT_USER', res.data.user)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    deleteUser({commit}, user) {
        axios.delete(`admin/users/${user.id}`)
            .then(res => {
                if (res.data === 'success') {
                    commit('DELETE_USER', user)
                }
            }).catch(err => {
            console.log(err)
        })
    },
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            axios.post('login', user, {
                timeout: 30000,
                validateStatus: function (status) {
                    return status >= 200 && status < 300; // default
                },
            })
                .then(res => {
                    commit('LOGIN', res.data)
                    console.log(res.data)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    logout() {
        AppStorage.clear()
    },
};

const mutations = {
    CREATE_USER(state, user) {
        state.users.unshift(user)
    },
    FETCH_USERS(state, users) {
        return state.users = users
    },
    FETCH_USER(state, user) {
        return state.user = user
    },
    EDIT_USER(state, user) {
        let index = state.users.findIndex(item => item.id === user.id)
        state.users.splice(index, 1, user)
    },
    DELETE_USER(state, user) {
        let index = state.users.findIndex(item => item.id === user.id)
        state.users.splice(index, 1)
    },
    LOGIN(state, user) {

        User.responseAfterLogin(user)
        return state.user = user.user
    },
    LOGOUT(state) {
        state.users = []
        state.user = ''
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

