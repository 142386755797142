import HomeView from "@/views/HomeView";

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/categories', name: 'categories',
        component: () => import('../views/categories/categoriesView.vue'),
    },
    {
        path: '/products', name: 'products',
        component: () => import('../views/products/ProductsView')
    },
    {
        path: '/stocks', name: 'stocks',
        component: () => import('../views/stocks/Index'),
        children: [
            {
                path: '',
                name: 'AllStocks',
                component: () => import('../views/stocks/List')
            },
            {
                path: ':id',
                name: 'StockDetails',
                component: () => import('../views/stocks/Details')
            },
        ]
    },

    {
        path: '/pos', name: 'AllPoss', component: () => import('../views/pos/Index'),
        children: [
            {
                path: '',
                name: 'pos',
                component: () => import('../views/pos/PostsOfSalesView')
            },
            {
                path: ':id',
                name: 'PosDetails',
                component: () => import('../views/pos/Details')
            },
            {
                path: 'session/:id',
                name: 'SessionDetails',
                component: () => import('../views/pos/SessionDetails')
            },
        ]
    },
    {
        path: "/zones",
        name: "ZonesGeo",
        component: () =>
            import("../views/zones/Index"),
        meta: {admin: true},
        redirect: {name: "AllZones"},
        children: [
            {
                path: "liste",
                name: "AllZones",
                component: () =>
                    import("../views/zones/All"),
                meta: {admin: true}
            },
            {
                path: "details/:id",
                name: "ZoneDetails",
                component: () =>
                    import(
                        "../views/zones/Details"
                        ),
                meta: {admin: true}
            },
            {
                path: "creation",
                name: "NewZone",
                component: () =>
                    import("../views/zones/New"),
                meta: {admin: true}
            }
        ]
    },

    {
        path: '/admin',
        component: () => import('../views/pos/Index.vue'),
        meta: {
            requiresAdmin: false
        },
        children: [
            {
                path: 'users',
                name: 'users',
                component: () => import('../views/users/UsersView.vue')
            },
            {
                path: 'rights', name: 'rights',
                component: () => import('../views/users/RightsView.vue')
            },
            {
                path: 'roles', name: 'roles',
                component: () => import('../views/users/RolesView.vue')
            },
            {
                path: 'reports', name: 'reports', meta: {
                    requiresAdmin: true
                },
            },
            {
                path: 'settings', name: 'settings', meta: {
                    requiresAdmin: true
                },
            },
            {
                path: '',
                redirect: 'users'
            }
        ]
    },
    {
        path: '/', name: 'login',
        component: () => import('../views/users/auth/LoginView')
    },
]

export default routes