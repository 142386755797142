import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import {Ripple} from 'vuetify/lib/directives'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const opts = {

    rtl: false,
    directives: {
        Ripple
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#1b743e',
                secondary: '#0E5C86',
                light: '#F5F5F5',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
            dark: {
                primary: '#7957d5',
                green: "#00A95C",
                red: "#D01C1F",
                yellow: "#FFD700",
            },
        },
    },


}

export default new Vuetify(opts);
