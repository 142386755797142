import axios from '@/axios'

const state = {
    poss: [],
    pos: '',
    poss_session: '', beneficaires : 0,
    pos_count : 0,
    pos_active : 0,
    sales_month : 0,
    total_units : 0,
    total_by_product : 0,
};
const getters = {
    poss: (state) => state.poss,
    pos: (state) => state.pos,
    poss_session: (state) => state.poss_session,
    beneficaires : (state)=>state.beneficaires,
    pos_count : (state)=>state.pos_count,
    pos_active : (state)=>state.pos_active,
    sales_month : (state)=>state.sales_month,
    total_units : (state)=>state.total_units,
    total_by_product : (state)=>state.total_by_product,
};

const actions = {

    createPos({commit}, pos) {
        return new Promise((resolve, reject) => {
            axios.post('poss', pos)
                .then(response => {
                    commit('CREATE_POS', response.data.pos);
                    console.log(response)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchPoss({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('poss')
                .then(res => {
                    commit('FETCH_POSS', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    getStats({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('stats')
                .then(res => {
                    commit('SET_STATS', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    fetchPos({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.get('poss/' + id)
                .then(res => {
                    commit('FETCH_POS', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    fetchPosSession({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.get('poss/session/' + id)
                .then(res => {
                    commit('SET_POS_SESSION', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    updatePos({commit}, pos) {
        return new Promise((resolve, reject) => {
            axios.put('poss/' + pos.id, pos)
                .then(res => {
                    commit('EDIT_POS', res.data.pos)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    deletePos({commit, dispatch}, pos) {
        return new Promise((resolve, reject) => {
            axios.delete(`poss/${pos.id}`)
                .then(res => {
                    commit('DELETE_POS', pos)
                    dispatch("fetchPoss")
                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })

    }
};

const mutations = {

    CREATE_POS(state, pos) {
        //state.poss.unshift(pos)
    },
    SET_STATS(state, payload) {
        state.beneficaires = payload.beneficaires
        state.pos_count = payload.pos_count
        state.pos_active = payload.pos_active
        state.sales_month = payload.sales_month
        state.total_units = payload.total_units
        state.total_by_product = payload.total_by_product

    },
    SET_POS_SESSION(state, pos) {
        state.poss_session = pos
    },
    FETCH_POSS(state, poss) {
        return state.poss = poss
    },
    FETCH_POS(state, pos) {
        return state.pos = pos
    },
    EDIT_POS(state, pos) {
        // let index = state.poss.findIndex(item => item.id === pos.id)
        // state.poss.splice(index, 1, pos)
    },
    DELETE_POS(state, pos) {
        // let index = state.poss.findIndex(item => item.id === pos.id)
        // state.poss.splice(index, 1)
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}

