import axios from './../../axios'

const state = {
    stocks: [],
    stock: '',
};
const getters = {
    allStocks: (state) => state.stocks,
    stock: (state) => state.stock,
};

const actions = {
    async fetchStocks({commit}) {
        const response = await axios.get('/stocks');
        commit('setStocks', response.data);
    },
    async fetchStock({commit}, id) {
        const response = await axios.get('/stocks/' + id);
        commit('setStock', response.data);
    },
    async createStock({commit, dispatch}, payload) {
        const response = await axios.post('/stocks/', payload);
        dispatch('fetchStocks');
    },
};
const mutations = {
    setStocks: (state, val) => (state.stocks = val),
    setStock: (state, val) => (state.stock = val),
};
export default {
    state,
    getters,
    actions,
    mutations
}
