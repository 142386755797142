import axios from '@/axios'

const state = {
    products: [],
    product: '',
};
const getters = {
    products: (state) => state.products,
    product: (state) => state.product,
};

const actions = {
    createProduct({commit}, product) {
        return new Promise((resolve, reject) => {
            axios.post('products', product)
                .then(response => {
                    commit('CREATE_PRODUCT', response.data.product);
                    console.log(response)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchProducts({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('products')
                .then(res => {
                    commit('FETCH_PRODUCTS', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    fetchProduct({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.get('products/' + id)
                .then(res => {
                    commit('FETCH_PRODUCT', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    updateProduct({commit}, product) {
        return new Promise((resolve, reject) => {
            axios.put('products/' + product.id, product)
                .then(res => {
                    commit('EDIT_PRODUCT', res.data.product)
                    resolve(res)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    deleteProduct({commit}, product) {
        return new Promise((resolve, reject) => {
            axios.delete(`products/${product.id}`)
                .then(res => {
                    commit('DELETE_PRODUCT', product)
                    resolve(res)
                }).catch(err => {
                reject(err)
                console.log(err)
            })
        })

    }
};

const mutations = {

    CREATE_PRODUCT(state, product) {
        //state.products.unshift(product)
    },
    FETCH_PRODUCTS(state, products) {
        return state.products = products
    },
    FETCH_PRODUCT(state, product) {
        return state.product = product
    },
    EDIT_PRODUCT(state, product) {
        let index = state.products.findIndex(item => item.id === product.id)
        state.products.splice(index, 1, product)
    },
    DELETE_PRODUCT(state, product) {
        let index = state.products.findIndex(item => item.id === product.id)
        state.products.splice(index, 1)
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}

