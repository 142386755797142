import Token from './Token'
import AppStorage from './AppStorage'
import user from "@/store/modules/user";

class User {

    responseAfterLogin(res) {
        const access_token = res.access_token
        const user = res.user
        AppStorage.store(access_token, user)
    }

    hasToken() {
        const storeToken = localStorage.getItem('token');
        if (storeToken) {
            return storeToken
        }
        return false
    }

    getToken() {
        return localStorage.getItem('token')
    }

    isLoggedIn() {
        return this.hasToken()
    }

    logout() {
        AppStorage.clear()
    }

    name() {
        if (this.isLoggedIn()) {
            return this.getUser().name
        }
    }

    role() {
        if (this.isLoggedIn()) {
            return this.getUser().role
        }
    }

    isAdmin(user){
        return localStorage.getItem('role') == "admin";
        user = localStorage.getItem('user');
        if(user != 'undefined' && user.length > 0){
            return JSON.parse(user).roles.some(role => role.name === 'admin');
        }
        return false;
    }

    id() {
        if (this.isLoggedIn()) {
            const payload = Token.payload(localStorage.getItem('token'));
            return payload.sub
        }
        return false
    }

    hasRole(admin) {
        admin = AppStorage.getUser()
        if(admin.length > 0){
            return JSON.parse(admin).roles.some(role => role.name === 'admin');
        }
        return false;
    }

    getUser() {
       let user  = localStorage.getItem('user');

        return {
            name: JSON.parse(user).name,
            role: JSON.parse(user).roles[0].name,
        };
    }

}

export default User = new User()
