import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/modules/user";
import category from "@/store/modules/category";
import product from "@/store/modules/product";
import stock from "@/store/modules/stock";
import pos from "@/store/modules/pos";
import zones from "@/store/modules/zones";
import AppStorage from "@/Helpers/AppStorage";
import i18n from "@/i18n";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale : AppStorage.getLocale(),
  },
  getters : {
    locale : state => state.locale,
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
      AppStorage.setLocale(locale);
      i18n.locale = locale;
    }
  },
  actions: {
    setLocale({commit}, locale) {
      commit('SET_LOCALE', locale);
    }
  },
  modules: {
    user,
    category,
    product,
    pos,
    stock,
    zones
  }
})
