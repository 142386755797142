import axios from '@/axios'

const state = {
    categories: [],
    category: '',
};
const getters = {
    categories: (state) => state.categories,
    category: (state) => state.category,
};

const actions = {
    createCategory({commit}, category) {
        return new Promise((resolve, reject) => {
            axios.post('categories', category)
                .then(response => {
                    commit('CREATE_CATEGORY', response.data.category);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchCategories({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('categories')
                .then(res => {
                    commit('FETCH_CATEGORIES', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    fetchCategory({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.get('categories/' + id)
                .then(res => {
                    commit('FETCH_CATEGORY', res.data)
                    resolve(res.data)
                }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    editCategory({commit}, category) {
        return new Promise((resolve, reject) => {
            axios.put('categories/' + category.id, category)
                .then(res => {
                    commit('EDIT_CATEGORY', res.data.category)
                    resolve(res)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    },

    deleteCategory({commit}, category) {
        return new Promise((resolve, reject) => {
            axios.delete(`categories/${category.id}`)
                .then(res => {
                    commit('DELETE_CATEGORY', category)
                    resolve(res.data)
                    // if (res.data === 'success') {
                    //
                    // }
                }).catch(err => {
                reject(err)
                console.log(err)
            })
        })

    }
};

const mutations = {

    CREATE_CATEGORY(state, category) {
        state.categories.unshift(category)
    },
    FETCH_CATEGORIES(state, categories) {
        return state.categories = categories
    },
    FETCH_CATEGORY(state, category) {
        return state.category = category
    },
    EDIT_CATEGORY(state, category) {
        // let index = state.categories.findIndex(item => item.id === category.id)
        // state.categories.splice(index, 1, category)
    },
    DELETE_CATEGORY(state, category) {
        let index = state.categories.findIndex(item => item.id === category.id)
        state.categories.splice(index, 1)
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}

