import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notification from "@/Helpers/Notification";
import User from '@/Helpers/User';
import Noty from 'noty';

window.Noty = Noty;

window.User = User;

window.Notification = Notification;
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Authorization'] = `Bearer ${User.getToken()}`;
Vue.config.productionTip = false

import SweetAlert from 'sweetalert2'
import i18n from './i18n'

window.SweetAlert = SweetAlert;

const Toast = SweetAlert.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', SweetAlert.stopTimer)
    toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
  }
});

window.Toast = Toast
window.Reload = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
