import axios from 'axios'
import AppStorage from "@/Helpers/AppStorage";

export default axios.create({
    baseURL: 'https://backend-temwin.csa.gov.mr/api/',

    //baseURL: 'http://127.0.0.1:8000/api/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + AppStorage.getToken()
    }
});
