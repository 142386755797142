<template>
  <v-navigation-drawer
      color="light"
      v-model="drawerFlag"
      app>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          hello
          <img
              src="https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg"
              alt="">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sidi oumar</v-list-item-title>
          <v-list-item-subtitle>Administrateur</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list
        nav
        dense
    >
      <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
          active-class="active"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "SideBar",
  data() {
    return {
      drawer: true,
      items: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/home'},
        {title: 'Catégories', icon: 'mdi-format-list-checkbox', link: '/categories'},
        {title: 'Produits', icon: 'mdi-shopping', link: '/products'},
        {title: 'Stocks', icon: 'mdi-stocking', link: '/stocks'},
        {title: 'POS', icon: 'mdi-help-box', link: '/pos'},
        // {title: 'Notifications', icon: 'mdi-bell', link: '/notifications'},
        //{title: 'Utilisateurs', icon: 'mdi-account-group', link: '/users'},
      ],
      right: null,
    }
  },

  computed: {
    drawerFlag: {
      get: function () {
        return this.drawer
      },
      set: function (val) {
        this.$emit('emitterdrawer', val)
      }
    }
  },
}
</script>

<style scoped>
.active {
  background: #704232;
  color: #fff !important;
}
</style>
