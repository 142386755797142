class AppStorage {

    storeToken(token) {
        localStorage.setItem('token', token);
    }

    storeUser(user) {
        localStorage.setItem('user', user);
    }

    store(token, user) {
        this.storeToken(token)
        localStorage.setItem('user', user)
        this.storeUser(JSON.stringify(user))
    }

    clear() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getUser() {
        return localStorage.getItem('user');
    }

    isAdmin() {
        return localStorage.getItem('role') === "admin";
        let user = localStorage.getItem('user');
        if (user !== 'undefined' && user.length > 0) {
            return JSON.parse(user).roles.some(role => role.name === 'admin');
        }
        return false;
    }

    getLocale() {
        return localStorage.getItem('lang');
    }

    setLocale(locale) {
        localStorage.setItem('lang', locale);
    }

}

export default AppStorage = new AppStorage();
