<template>
  <v-app-bar flat app>

    <v-app-bar-nav-icon
        v-if="$route.path !== '/'"
        @click.stop="toggleDrawer">

    </v-app-bar-nav-icon>

    <v-spacer></v-spacer>

    <v-btn color="primary" @click="toggleLanguage('fr')" tag="button" x-small v-if="$i18n.locale === 'ar'">
      Français
    </v-btn>
    <v-btn color="primary" @click="toggleLanguage('ar')" tag="button" x-small v-else>
      العربية
    </v-btn>

    <v-btn tag="button" text small @click="logout">
      <v-icon color="error">mdi-logout</v-icon>
      {{ $t('logout') }}
    </v-btn>

  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",

  data() {
    return {
      // drawer: true
    }
  },
  methods: {
    toggleDrawer() {
      this.$emit('toggle-drawer')
    },
    toggleLanguage(lang) {
      this.$store.dispatch('setLocale', lang)
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>

<style scoped>

</style>
