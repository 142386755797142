<template>
  <v-app :dir="$store.state.locale === 'ar' ? 'rtl' : 'ltr'">
    <v-navigation-drawer
        :right="$store.state.locale === 'ar'"
        v-if="$route.path !== '/'"
        color="light"
        v-model="drawer"
        app>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img
                src="img/avatar.png"
                alt="">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list
          nav
          dense
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link"
            active-class="active"
            :exact="item.exact"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            v-if="isAdmin"
            v-for="item in adminItems"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="child in item.items"
              :key="child.title"
              link
              :to="child.link"
              active-class="active"
              :append-icon="child.action"
          >

            <v-list-item-icon>
              <v-icon>{{ child.action }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!--      <v-list >-->

      <!--      </v-list>-->
    </v-navigation-drawer>

    <NavBar
        v-if="$route.name !== 'login' && $route.name !== 'register'"
        @toggle-drawer="toggleDrawer"
        @toggle-language="toggleLanguage"
    />

    <v-main>

      <transition name="fade" mode="out-in">

        <v-container full-height>

          <router-view></router-view>

        </v-container>

      </transition>

    </v-main>

  </v-app>
</template>

<script>

import SideBar from "@/components/SideBar";
import NavBar from "@/components/NavBar";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {NavBar, SideBar},
  data() {
    return {
      user: {
        name: '',
        role: ''
      },
      drawer: true,

      right: null,
      isAdmin: true,
    }
  },
  computed: {
    adminItems() {
      return  [
        {
          title: this.$t('administrator'),
          action: 'mdi-cog',
          items: [
            {
              title: this.$t('users'),
              link: '/admin/users',
              action: 'mdi-account-multiple'
            },
            // {
            //   title: this.$t('roles'),
            //   link: '/admin/roles',
            //   action: 'mdi-account-key'
            // },
            // {
            //   title: this.$t('rights'),
            //   link: '/admin/rights',
            //   action: 'mdi-lock'
            // }
          ]
        }
      ]
    },
    items (){
      return [
        {title: this.$t('dashboard'), icon: 'mdi-view-dashboard', link: '/home'},
        {title: this.$t('pos'), icon: 'mdi-store', link: '/pos'},
        {title: this.$t('categories'), icon: 'mdi-format-list-checkbox', link: '/categories'},
        {title: this.$t('products'), icon: 'mdi-barley', link: '/products'},
        {title: this.$t('stocks'), icon: 'mdi-store', link: '/stocks'},
        //{title: this.$t('zones'), icon: 'mdi-map-search', link: '/zones'},
      ]
    } ,
  },
  comments: {
    SideBar,
    NavBar
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleLanguage() {

      if (this.$i18n.locale === 'ar') {
        this.$i18n.locale = 'fr'
        localStorage.setItem("lang", 'fr')
        //this.$vuetify.rtl = false
      } else {
        this.$i18n.locale = 'ar'
        localStorage.setItem("lang", 'ar')
        //this.$vuetify.rtl = true
      }
    },
  },
  created() {
    console.log('created')
    let lang = localStorage.getItem("lang")
    if (lang) {
      console.log(lang)
      this.$i18n.locale = lang
    }
    if (User.isLoggedIn()) {
      console.log("logged")
      this.user = User.getUser();
      //this.isAdmin = User.role() === 'admin';
    }
  },
  watch: {
    $route(to, from) {
      // if (User.isLoggedIn()) {
      //   this.user = User.getUser();
      //   this.isAdmin = User.role() === 'admin';
      // }
    }
  },
};
</script>

<style scoped>
.active {
  background: #1b743e;
  color: #fff !important;
}
</style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@600;700&display=swap');

* {
  font-family: 'Noto Naskh Arabic', sans-serif;
}
</style>
